import { Backdrop, Box, Button, CircularProgress, Grid, InputLabel, ListSubheader } from '@mui/material';
import * as React from 'react';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { Service } from './services/Service';
import { useEffect, useState } from 'react';

export default function OrderMBCallbackBody() {
  const [statusText, setStatusText] = useState('đang xử lý');
  const [statusImage, setStatusImage] = useState("./static/images/process.png");

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    let transactionId = localStorage.getItem('transactionId') || '';
    Service.CheckMBStatus(transactionId).then(res => {
        if(res.data.status == 'PAID')
        {
          setStatusText('thành công');
          setStatusImage("./static/images/check.png");
        }
    })
  }, [])  

  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <LocalAtmOutlinedIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THANH TOÁN</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box 
      component="form"
      sx={{'& .MuiTextField-root': { m: 1 }, backgroundColor:'#ffffff' }}
      noValidate 
      autoComplete="off"
      >
        <div>
        <Grid container>
            <Grid item xs={11} sx={{textAlign:'left', height: window.innerHeight - 400, maxHeight: '480px'}}>
              <Grid container>
                <Grid item xs={11} sx={{textAlign:'center'}}>
                <Box component="img" sx={{height: '50px',width: '50px'}} src={statusImage}/>
                </Grid>
                <Grid item xs={11} sx={{textAlign:'center'}}>
                  <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px',marginBottom: '10px', fontWeight: 'bold'}}>Thanh toán {statusText}</InputLabel> 
                </Grid>
                <Grid item xs={11} sx={{textAlign:'center'}}>
                  <InputLabel htmlFor="outlined-adornment-amount" sx={{color:'black', paddingLeft: '15px'}}><Button variant="contained" onClick={()=>navigate("/History")}>Chi tiết đơn hàng</Button></InputLabel> 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>

      </Box>
      <OrderFooter/>
    </Box>
  );
}