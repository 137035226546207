import { Backdrop, Box, Button, CircularProgress, Grid, InputLabel, ListSubheader, TableRow } from '@mui/material';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import OrderFooter from './OrderFooter'
import { useNavigate } from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { IRequestCreateCart } from './services/Type';
import { IPassenger } from './services/Type';
import {Service} from './services/Service';
import { useEffect, useState } from 'react';

const NotePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  border:'none'
}));

export default function OrderPaymentConfirmBody() {


  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState(false);


  return (
    <Box sx={{ width: '100%' , backgroundColor: '#29ACE3', height: '100%' }}>
      <Grid xs={12} sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
      <Grid container>
          <Grid item xs={1} sx={{height:'35px', paddingLeft: 1}}>
            <LocalAtmOutlinedIcon/>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'left'}}>
            <ListSubheader component="div" sx={{ paddingLeft:'1px', color:'#0D11B2', lineHeight: '25px', fontWeight: 'bold', fontSize: '16px' }}>THÔNG TIN THANH TOÁN</ListSubheader>
          </Grid>
        </Grid>
      </Grid>
      <Box 
      component="form"
      sx={{'& .MuiTextField-root': { m: 1 }, backgroundColor:'#ffffff' }}
      noValidate 
      autoComplete="off"
      >
        <div>
          <Grid container>
            <Grid item xs={11} sx={{textAlign:'left', height: window.innerHeight - 440}}>
              <NotePaper variant="outlined" sx={{paddingTop: '30px', textAlign: 'center', fontWeight: 'bold'}}>Bạn đã đặt vé thành công, vui lòng kiểm tra vé ở mục “Đơn hàng “ sau 15 phút</NotePaper>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="section" sx={{ textAlign: 'center', paddingTop:2,paddingBottom:2, backgroundColor:'#ffffff', borderBottomRightRadius: 18, borderBottomLeftRadius: 18}}>
        <Button variant="contained" onClick={()=>navigate("/History")}>
         Xem đơn hàng
        </Button>
      </Box>
      <OrderFooter/>
    </Box>
  );
}