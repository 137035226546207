import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const FooterPage = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2
}));

export default function HomeNewsImageList() {
  const navigate = useNavigate();
  const location = useLocation();
  var step = "trip";
  if(location.pathname == '/OrderSearch')
  {
    step = "trip";
  }
  else if(location.pathname == '/OrderTripFrom' || location.pathname == '/OrderTripTo')
  {
    step = "trip";
  }
  else if(location.pathname == '/OrderCustomer')
  {
    step = "customer";
  }
  else if(location.pathname == '/OrderBill')
  {
    step = "customer";
  }
  else if(location.pathname == '/OrderAction')
  {
    step = "passenger";
  }
  else if(location.pathname == '/OrderPassenger' || location.pathname == '/OrderPassengerNext')
  {
    step = "passenger";
  }
  else if(location.pathname == '/OrderChair' || location.pathname == '/OrderChairTo')
  {
    step = "chair";
  }
  else if(location.pathname == '/OrderPaymentTotal' || location.pathname == '/OrderPaymentConfirm' || location.pathname == '/OrderMBCallback' || location.pathname == '/OrderPaymentMB')
  {
    step = "payment";
  }
  return (
    <Box sx={{ height: '160px', width: '100%', backgroundColor: '#ffffff'}}>
      <FooterPage sx={{ height: '150px', borderBottomRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: '#29ACE3' , borderTopRightRadius: '0', borderTopLeftRadius: '0'}} elevation={0}>
        <FormControl sx={{ paddingLeft: '20px' }}>
          <FormLabel sx={{ height: '15px' }} id="demo-radio-buttons-group-label">&nbsp;</FormLabel>
          <RadioGroup
            sx={{ fontWeight:'bold' }}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue = {step}
            name="radio-buttons-group"
          >
            <FormControlLabel value="trip" control={<Radio sx={{'&.Mui-checked': {color: '#03FF00'}}}/>} label="CHỌN TUYẾN" sx={{ height: '25px' }} />
            <FormControlLabel value="customer" control={<Radio sx={{'&.Mui-checked': {color: '#03FF00'}}}/>} label="THÔNG TIN KHÁCH HÀNG" sx={{ height: '25px' }}/>
            <FormControlLabel value="passenger" control={<Radio sx={{'&.Mui-checked': {color: '#03FF00'}}}/>} label="THÔNG TIN HÀNH KHÁCH" sx={{ height: '25px' }} />
            <FormControlLabel value="chair" control={<Radio sx={{'&.Mui-checked': {color: '#03FF00'}}}/>} label="CHỌN GHẾ" sx={{ height: '25px' }} />
            <FormControlLabel value="payment" control={<Radio sx={{'&.Mui-checked': {color: '#03FF00'}}}/>} label="THANH TOÁN" sx={{ height: '25px' }} />
          </RadioGroup>
        </FormControl>
      </FooterPage>
    </Box>
  );
}