import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Service} from './services/Service';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Backdrop, Box, CircularProgress, TextField } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const BodyPage = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  }));

function createData(
  ticketType: string,
  ticketClass: string,
  value: string,
) {
  return { ticketType, ticketClass, value };
}

export default function PriceBody() {
  const [dataOptions, setDataOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dataRoutes, setDataRoutes] = useState([]);

  function callGetPrices(searchTerm:string)
  {
    setOpen(true);
    Service.GetPrices(searchTerm).then(res => {
      const updatedOptions = res.map((row: {ticketType: string, ticketClass: string; value: number }) => {
        return createData(row.ticketType, row.ticketClass, new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(row.value));
      });
      setDataOptions(updatedOptions);
      setOpen(false);
    })
  }

  useEffect(() => {
    setOpen(true);
    Service.GetRoutes().then(res => {
          const updatedOptions = res.map((row: { name: string; id: string; }) => {
            return { label: row.name, id: row.id };
          });
          setDataRoutes(updatedOptions)
          setOpen(false);
      })
  }, [])

  return (
    <Box sx={{ width: '100%' , height: '100%' , backgroundColor: '#29ACE3'}}>
      <Box sx={{paddingTop: 2, borderTopRightRadius: 18, borderTopLeftRadius: 18, backgroundColor:'#ffffff'}}>
        <BodyPage elevation={0}>
          <Autocomplete
            disablePortal
            blurOnSelect="touch"
            options={dataRoutes}
            onChange={(event, newValue) => {
              const tmp = JSON.parse(JSON.stringify(newValue)) || [];
              callGetPrices(tmp['id']);
            }}
            renderInput={(params) => <TextField {...params} name="Chọn tuyến" label="Vui lòng chọn tuyến"/>}
          />
          <TableContainer component={Paper} sx={{ height: window.innerHeight - 260, maxHeight: '480px' }}>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Đối tượng</TableCell>
                  <TableCell align="right">Loại vé</TableCell>
                  <TableCell align="right">Giá vé</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataOptions.map((row: {ticketType: string, ticketClass: string; value: string }) => (
                  <StyledTableRow>
                    <StyledTableCell align="left">{row.ticketType}</StyledTableCell>
                    <StyledTableCell align="right">{row.ticketClass}</StyledTableCell>
                    <StyledTableCell align="right">{row.value}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </TableContainer>
      </BodyPage>
    </Box>
  </Box>
  );
}